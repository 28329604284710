/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import {
  Collapse, Navbar, NavbarToggler, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Label,
} from 'reactstrap';
import { navigate } from '@reach/router';
import { Link } from 'gatsby';
import {
  useSelector, shallowEqual, useDispatch,
} from 'react-redux';
import './header.css';
import ImgLogo from '../images/logoLNext-edit-old-3nd.png';
import User from '../images/user.png';
import { logoutCustomer } from '../redux/actions/customer/auth';
import { logoutDriver } from '../redux/actions/driver/auth';
import ModalConfirm from '../views/util/components/ModalConfirm';
import { deleteMemberShip, resetMessageUpdate } from '../redux/actions/customer/customer';
import { deleteMemberShipDriver } from '../redux/actions/driver/driver';
import ModalCommon from '../views/util/components/ModalCommon';

const Customer = ({ subUrl, name, func }) => {
  const isLoggedInCustomer = useSelector(
    (state: any) => state.authCustomer.isLoggedIn, shallowEqual,
  );
  const customerInfo = useSelector(
    (state: any) => state.authCustomer.customerInfo, shallowEqual,
  );
  const companyName = customerInfo?.profile?.company_name !== undefined ? customerInfo?.profile?.company_name : '';
  const nameInfo = name.length > 0 ? name : `${companyName} ${customerInfo?.familyName} ${customerInfo?.firstName}様`;
  return (
    <Link
      {...(func ? { onClick: func } : '')}
      to={(isLoggedInCustomer === true) ? `/customer${subUrl || ''}` : '/customer/login'}
      className="user-display-name"
    >
      {
        (isLoggedInCustomer === true) ? nameInfo : 'ログイン'
      }
    </Link>
  );
};

const Driver = ({ subUrl, name, func }) => {
  const isLoggedInDriver = useSelector(
    (state: any) => state.authDriver.isLoggedIn, shallowEqual,
  );
  const driverInfo = useSelector(
    (state: any) => state.authDriver.driverInfo, shallowEqual,
  );
  const companyName = driverInfo?.CompanyName !== undefined ? driverInfo?.CompanyName : '';
  const nameInfo = name.length > 0 ? name : `${companyName} ${driverInfo?.familyName} ${driverInfo?.firstName}様`;
  return (
    <Link
      {...(func ? { onClick: func } : '')}
      to={(isLoggedInDriver === true) ? `/member${subUrl || ''}` : '/member/login'}
      className="user-display-name"
    >
      {
        (isLoggedInDriver === true) ? nameInfo : 'ログイン'
      }
    </Link>
  );
};

const Header = ({ role }: any) => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [roles, setRoles] = useState('');
  const dispatch = useDispatch();
  const accessToken = useSelector(
    (state: any) => state.authCustomer.accessToken,
    shallowEqual,
  );
  const accessTokenDriver = useSelector(
    (state: any) => state.authDriver.accessToken,
    shallowEqual,
  );
  const isSuccess = useSelector(
    (state: any) => state.customer.isSuccess, shallowEqual,
  );
  const driverInfo = useSelector(
    (state: any) => state.authDriver.driverInfo, shallowEqual,
  );
  const customerInfo = useSelector(
    (state: any) => state.authCustomer.customerInfo, shallowEqual,
  );
  const handleLogoutCustomer = () => {
    dispatch(logoutCustomer());
  };

  const handleLogoutDriver = () => {
    dispatch(logoutDriver());
  };

  const handleRemoveCustomer = () => {
    setRoles('customer');
    setOpen(true);
  };

  const handleRemoveDriver = () => {
    setRoles('member');
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleSubmitModal = () => {
    if (roles === 'customer') {
      dispatch(deleteMemberShip(accessToken));
    } else {
      dispatch(deleteMemberShipDriver(accessTokenDriver));
    }
    setOpen(false);
    setOpenModal(true);
  };
  const handleCloseModalCommon = () => {
    setOpenModal(false);
    if (roles === 'customer') {
      handleLogoutCustomer();
    } else {
      handleLogoutDriver();
    }
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(resetMessageUpdate());
    }
  }, [isSuccess, dispatch]);
  return (
    <header>
      <Link
        to={role === 'customer' ? '/customer/' : '/member/'}
      >
        <div className="header-logo">
          <img src={ImgLogo} alt="logo" className="logo-header-h" />
        </div>
        {/* <div className="header-logo">
          <img alt="logo" src={Logo} />
        </div> */}
      </Link>
      <div className="header-nav">
        <Link
          to={role === 'customer' ? '/customer/contact' : '/member/contact'}
          className="contact-btn"
        >
          お問い合わせ
        </Link>
        <Link
          to={role === 'customer' ? '/customer/contact' : '/member/contact'}
          className="btn-contact-mobile"
        >
          <span className="navbar-toggler-icon" />
        </Link>
        {
          role === 'customer' ? <Customer func={null} subUrl="/me" name="" /> : <Driver func="" subUrl="/me" name="" />
        }
        <Navbar expand style={{ left: 'auto' }}>
          <Collapse navbar>
            <Nav className="mr-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <img alt="user" src={User} style={{ width: '36px', height: '36px', margin: '0px' }} />
                </DropdownToggle>
                <DropdownMenu right style={{ margin: '10px' }}>
                  <DropdownItem>
                    {
                      role === 'customer' ? <Customer func={null} subUrl="/me/edit" name="プロフィール編集" /> : <Driver func={null} subUrl="/me/edit" name="プロフィール編集" />
                    }
                  </DropdownItem>
                  <DropdownItem>
                    {
                      role === 'customer' ? <Customer func={null} subUrl="/change_password" name="パスワード変更" /> : <Driver func={null} subUrl="/change_password" name="パスワード変更" />
                    }
                  </DropdownItem>
                  <DropdownItem>
                    {
                      role === 'customer' ? <Customer func={null} subUrl="/change_email" name="メールアドレス変更" /> : <Driver func={null} subUrl="/change_email" name="メールアドレス変更" />
                    }
                  </DropdownItem>
                  <DropdownItem>
                    {
                      role === 'customer' ? <Customer func={null} subUrl="/frequently_asked_questions" name="よくあるご質問" /> : <Driver func={null} subUrl="/frequently_asked_questions" name="よくあるご質問" />
                    }
                  </DropdownItem>
                  <DropdownItem>
                    {
                      role === 'customer' ? <Customer func={handleLogoutCustomer} subUrl="/login" name="ログアウト" /> : <Driver func={handleLogoutDriver} subUrl="/login#" name="ログアウト" />
                    }
                  </DropdownItem>
                  {/* <DropdownItem>
                    {
                      role === 'customer' && (
                        <div className="btn-remove" onClick={handleRemoveCustomer}>
                          退会する
                        </div>
                      )
                    }
                    {
                      role === 'member' && (<div className="btn-remove"
                      onClick={handleRemoveDriver}>退会する</div>)
                    }
                  </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
        <ModalConfirm text="退会した場合、投稿内容が全て削除されます。また、再開する場合は再度会員登録をお願いいたします。本当に退会してもよろしいですか？" handleSubmitModal={handleSubmitModal} handleCloseModal={handleCloseModal} statusModal={open} labelSubmit="退会する" labelCancel="キャンセル" />
        <ModalCommon handleCloseModal={handleCloseModalCommon} text="退会が完了しました。" statusModal={openModal} />
      </div>
    </header>
  );
};

export default Header;
